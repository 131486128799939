import React from 'react'

import ErrorFallback from './components/errorFallback'

import * as Sentry from '@sentry/react'
import { each } from 'lodash-es'
import ToastDisplay from 'r/components/toastDisplay'
import 'react-dates/initialize'
import { createRoot } from 'react-dom/client'

const mountedNodeRoots = new Map()
const mountables = {}

export function registerComponent(name, importFunction) {
  mountables[name] = importFunction
}

document.addEventListener('turbolinks:load', () => {
  each(document.querySelectorAll('[data-react-component]'), (node) => {
    const importFunction = mountables[node.dataset.reactComponent]

    if (importFunction) {
      importFunction().then(({ default: Component }) => {
        const props = JSON.parse(node.dataset.reactProps || '{}')

        let root = mountedNodeRoots.get(node)

        if (!root) {
          root = createRoot(node)
          mountedNodeRoots.set(node, root)
        }

        root.render(
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <ToastDisplay>
              <Component {...props} />
            </ToastDisplay>
          </Sentry.ErrorBoundary>
        )
      })
    }
  })
})

document.addEventListener('turbolinks:before-render', () => {
  for (const [node, root] of mountedNodeRoots) {
    root.unmount()
    mountedNodeRoots.delete(node)
  }
})
