import '/lib/assets/cookieControl-9.x.min'
import I18n, { t } from 'i18n'
import * as styles from 'styles/_variables.module'
import { getMetaValue } from 'utils'

const apiKey = getMetaValue('cookie_consent_api_key')
const licenseType = getMetaValue('cookie_consent_license_type')

const title = getMetaValue('cookie_consent_title')
const intro = getMetaValue('cookie_consent_intro')
const necessaryDescription = getMetaValue('cookie_consent_necessary_description')
const necessaryTitle = getMetaValue('cookie_consent_necessary_title')

const statementDescription = getMetaValue('cookie_consent_statement_description')
const statementName = getMetaValue('cookie_consent_statement_name')
const statementUpdated = getMetaValue('cookie_consent_statement_updated')

const env = getMetaValue('environment')

const branding = {
  acceptText: '#271a1a',
  acceptBackground: '#f8f9fa',
  backgroundColor: styles.primary,
  buttonIconHeight: '64px',
  buttonIconWidth: '64px',
  fontSizeTitle: '1.4em',
  notifyOnce: true,
  removeAbout: true
}

const statement = {
  description: statementDescription,
  name: statementName,
  url: '/cookies_policy',
  updated: statementUpdated
}

const text = {
  acceptSettings: t('consent.confirm'),
  title: title,
  intro: intro,
  necessaryDescription: necessaryDescription,
  necessaryTitle: necessaryTitle
}

export default function () {
  const config = {
    apiKey: apiKey,
    branding: branding,
    consentCookieExpiry: 365,
    initialState: 'open',
    locale: I18n.locale,
    mode: 'GDPR',
    necessaryCookies: ['_leis_session', 'LeisConsent'],
    position: 'right',
    product: licenseType,
    rejectButton: false,
    statement: statement,
    subdomains: false,
    text: text,
    theme: 'dark'
  }

  env !== 'test' && apiKey && window.CookieControl.load(config)
}
