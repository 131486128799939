import { each } from 'lodash-es'
import stickybits from 'stickybits'
import * as styles from 'styles/_variables.module'

let stickyInstances = []

function ensureCustomStickyHeaderWidth($referenceTable, $clonedTable) {
  let w, $currentTargetCol

  $referenceTable.find('tr').each(function (i, row) {
    $(row)
      .children()
      .each(function (j, e) {
        $currentTargetCol = $($clonedTable.find('tr')[i]).children()[j]
        w = e.getBoundingClientRect().width + 'px'
        $($currentTargetCol).css({ width: w, 'min-width': w, 'max-width': w })
      })
  })
}

function ensureCustomStickyHeaderHeight($referenceTable, $clonedTable) {
  let tw = $referenceTable[0].getBoundingClientRect().width + 'px'
  $($clonedTable).css({ width: tw, 'min-width': tw, 'max-width': tw })
}

;(function () {
  var win = jQuery(window),
    prev_width = win.width()
  win.on('resize', function () {
    const width = win.width()

    if (width === prev_width) {
      return
    }
    win.trigger('hresize')
    prev_width = width
  })
})()

function customStickyHeaders() {
  $('.has-sticky-header').each(function () {
    const navbarHeight = document.querySelector('.navbar.fixed-top')?.clientHeight || styles.navbarHeight

    let $this = $(this)

    let $referenceTable = $this.find('.make-header-sticky')

    if (!$referenceTable) {
      return
    }

    let $clonedTable = $referenceTable.clone()
    $clonedTable.removeClass('make-header-sticky').addClass('the-sticky-header').attr('id', '')

    $clonedTable.appendTo($this)

    $clonedTable.find('tbody').remove()

    ensureCustomStickyHeaderHeight($referenceTable, $clonedTable)
    ensureCustomStickyHeaderWidth($referenceTable, $clonedTable)

    $(window).on('hresize', function () {
      ensureCustomStickyHeaderHeight($referenceTable, $clonedTable)
      ensureCustomStickyHeaderWidth($referenceTable, $clonedTable)
    })

    $(window).on('scroll', function () {
      const tableOffset = $this.offset().top + 10
      const windowTop = $(window).scrollTop()
      const tableHeight = $this.height() - $clonedTable.height()

      const isTableHeaderAboveViewport = windowTop + navbarHeight > tableOffset
      const isTableInOrBelowViewport = tableHeight + tableOffset > windowTop + navbarHeight

      $clonedTable.css('display', 'none')
      $clonedTable.removeClass('sticky-header-unglued').addClass('sticky-header-glued')

      if (isTableHeaderAboveViewport && isTableInOrBelowViewport) {
        $clonedTable.css('display', 'table')
        $clonedTable.removeClass('sticky-header-glued').addClass('sticky-header-unglued')
      }
    })
  })
}

export default function () {
  let navbarHeight = document.querySelector('.navbar.fixed-top')?.clientHeight || styles.navbarHeight

  let datagridForm = document.querySelector('.datagrid-form')
  let datagridFormHeight = datagridForm ? datagridForm.clientHeight : 0

  each(stickyInstances, (instance) => instance.cleanup())

  stickyInstances = []

  stickyInstances.push(stickybits('.datagrid-form', { stickyBitStickyOffset: navbarHeight }))
  stickyInstances.push(
    stickybits('.js-table--header th', { stickyBitStickyOffset: navbarHeight + datagridFormHeight - 1 })
  )

  customStickyHeaders()
}
