import numeralLocaleConfig from './numeral_locale'
import translations from './translations.json'

import { I18n as defaultI18n } from 'i18n-js'
import moment from 'moment'
import numeral from 'numeral'
import { getMetaValue } from 'utils'
import { setLocale } from 'yup'

const I18n = new defaultI18n()
I18n.store(translations)
I18n.defaultLocale = 'lv'
I18n.locale = document.documentElement.getAttribute('lang')
I18n.availableLocales = JSON.parse(getMetaValue('available_locales')) || [I18n.defaultLocale]

moment.locale(I18n.locale)

numeral.register('locale', 'default', numeralLocaleConfig)
numeral.locale('default')
numeral.defaultFormat('0,0.00')
numeral.nullFormat('')

const t = I18n.t.bind(I18n)

const INVALID_ERROR_MESSAGE = t('errors.messages.invalid')

const greaterThanOrEqualToTranslationPath = 'errors.messages.greater_than_or_equal_to'

setLocale({
  mixed: {
    default: INVALID_ERROR_MESSAGE,
    required: t('errors.messages.blank')
  },
  string: {
    matches: INVALID_ERROR_MESSAGE,
    email: INVALID_ERROR_MESSAGE,
    min: t(greaterThanOrEqualToTranslationPath, {
      count: '${min}'
    })
  },
  number: {
    min: t(greaterThanOrEqualToTranslationPath, {
      count: '${min}'
    }),
    max: t('errors.messages.less_than_or_equal_to', {
      count: '${max}'
    }),
    lessThan: t('errors.messages.less_than', { count: '${less}' }),
    moreThan: t('errors.messages.more_than', { count: '${more}' }),
    positive: t('activerecord.errors.messages.positive'),
    integer: t('errors.messages.not_an_integer')
  },
  array: {
    min: t(greaterThanOrEqualToTranslationPath, {
      count: '${min}'
    })
  }
})

export default I18n
export { t }
