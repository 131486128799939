import { getMetaValue } from '../utils'

import { startsWith } from 'lodash-es'

export default function () {
  const currentPath = getMetaValue('current_path')

  $('#navbar a.nav-link, #navbar a.dropdown-item').each((_idx, link) => {
    let $link = $(link)

    if (startsWith(currentPath, $link.attr('href'))) {
      $link.addClass('active')

      if ($link.hasClass('dropdown-item')) {
        $link.closest('.nav-item').addClass('show')
      }
    }
  })
}
