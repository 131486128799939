import React from 'react'

import classNames from 'classnames'
import { t } from 'i18n'
import styled, { css, keyframes } from 'styled-components'

const ToastBody = styled.div.attrs({ className: 'toast-body' })`
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  position: relative;
  ${({ $title }) => !$title && 'border-top-right-radius: 0.25rem;'}
  ${({ $title }) => !$title && 'border-top-left-radius: 0.25rem;'}

  a {
    color: inherit;
    text-decoration: underline;
  }
`

const progressKeyframes = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
`

const progressBarAnimation = ({ $messageTTL }) => css`
  ${$messageTTL}ms linear 0s 1 forwards ${progressKeyframes}
`

const ProgressBarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .progress {
    height: 2px;
    background-color: ${({ $isLight }) => ($isLight ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.1)')};
  }

  .progress-bar {
    height: 2px;
    background-color: ${({ $isLight }) => ($isLight ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.3)')};
    animation: ${progressBarAnimation};
  }
`

const Toast = ({ title, children, kind, messageTTL, onClose }) => {
  const isError = kind === 'error'
  const isSuccess = kind === 'success'
  const isInfo = kind === 'info'
  const isWarning = kind === 'warning'

  const messageClass = classNames({
    'bg-danger': isError,
    'bg-success': isSuccess,
    'bg-primary': isInfo,
    'text-white': isError || isSuccess || isInfo,
    'bg-warning': isWarning
  })

  const headerClass = classNames('toast-header', {
    'text-danger': isError
  })

  const buttonClass = classNames({
    'text-white': (isError || isSuccess || isInfo) && !title
  })

  const closeButton = (
    <button type='button' className='ml-2 close' onClick={onClose} aria-label='Close' title={t('close')}>
      <span className={buttonClass} aria-hidden='true'>
        &times;
      </span>
    </button>
  )

  const progressBar = (
    <ProgressBarWrapper $messageTTL={messageTTL} $isLight={isInfo || isError}>
      <div className='progress'>
        <div className='progress-bar' />
      </div>
    </ProgressBarWrapper>
  )

  return (
    <div className='toast show' role='alert' aria-live='assertive' aria-atomic='true'>
      {title && (
        <div className={headerClass}>
          <strong className='mr-auto'>{title}</strong>
          {closeButton}
        </div>
      )}
      <ToastBody className={messageClass} $title={title}>
        {!title && closeButton}
        {children}
        {progressBar}
      </ToastBody>
    </div>
  )
}

export default Toast
