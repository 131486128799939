export default function () {
  const qrs = document.querySelector('.qualification-request-approval-transition')
  if (qrs) {
    let $stateField = $('dd[data-contains="state"]')
    $stateField.html('<i class="fa fa-cog fa-spin" id="loading-state"></i>')
    $.ajax({
      url: qrs.dataset.path,
      type: 'POST',
      success: function (data) {
        $stateField.html('').text(data.new_state)
      }
    })
  }
}
