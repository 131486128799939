export default function () {
  if (!document.getElementById('edit_role')) {
    return
  }

  const rows = document.getElementsByClassName('policy-row')

  Array.prototype.forEach.call(rows, function (row) {
    const inputs = row.getElementsByClassName('check-action')

    const checkAll = row.getElementsByClassName('check-all')[0]

    checkAll.addEventListener('change', function (event) {
      const checked = event.target.checked

      Array.prototype.forEach.call(inputs, function (input) {
        input.checked = checked
      })
    })
  })
}
