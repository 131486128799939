// https://babeljs.io/docs/en/babel-polyfill.html
import Rails from '@rails/ujs'
import 'axiosDefaults'
import 'behaviors'
import 'bootstrap'
import 'channels'
import 'core-js/stable'
import 'errorReporting'
import 'i18n'
import 'procurementsEngine'
import 'r/components'
import 'r/setup'
import 'regenerator-runtime/runtime'
import 'styles'
import Turbolinks from 'turbolinks'
import 'yupDefaults'

Rails.start()
Turbolinks.start()
