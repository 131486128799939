import * as ActionCable from '@rails/actioncable'
import { getMetaValue } from 'utils'

ActionCable.logger.enabled = getMetaValue('environment') === 'development'

// Load all the channels within this directory and all subdirectories.
// Channel files must be named *Channel.js.

const channels = require.context('.', true, /Channel\.js$/)
channels.keys().forEach(channels)
