// extracted by mini-css-extract-plugin
var _1 = "#224a97";
var _2 = "#8c0000";
var _3 = "#f8f9fa";
var _4 = "#f2f3f9";
var _5 = "#c1c1bf";
var _6 = "#5e5d5a";
var _7 = "#495057";
var _8 = "#343a40";
var _9 = "#271a1a";
var _a = "#87bb21";
var _b = "#ced4da";
var _c = "#717593";
var _d = "#cbd9ff";
var _e = "#f7f7f7";
var _f = "#e7f3e1";
var _10 = "#ffb2b2";
var _11 = "#d4d9df";
var _12 = "50px";
var _13 = "#f3d49d";
var _14 = "#234a97";
var _15 = "#e70101";
var _16 = "#dee2e6";
export { _1 as "darkBlue", _2 as "darkRed", _3 as "gray100", _4 as "gray200", _5 as "gray500", _6 as "gray600", _7 as "gray700", _8 as "gray800", _9 as "gray900", _a as "green", _b as "inputBorderColor", _c as "labelColor", _d as "lightBlue", _e as "lightGray", _f as "lightGreen", _10 as "lightRed", _11 as "mediumGray", _12 as "navbarHeight", _13 as "orange", _14 as "primary", _15 as "red", _16 as "tableBorderColor" }
