export default function () {
  const manualBlock = document.querySelector('div#internal-sign-in-manual')
  const samlBlock = document.querySelector('div#internal-sign-in-saml')

  const handleClick = (selfBlock, otherBlock) => {
    selfBlock.classList.add('d-none')
    otherBlock.classList.remove('d-none')
  }

  samlBlock &&
    samlBlock.querySelector('button#switch').addEventListener('click', handleClick.bind(this, samlBlock, manualBlock))
  manualBlock &&
    manualBlock.querySelector('button#switch').addEventListener('click', handleClick.bind(this, manualBlock, samlBlock))
}
