import React, { useState } from 'react'

function FlashNotification({ position, type, message, ...props }) {
  const [open, setOpen] = useState(true)

  const onClick = () => setOpen(false)

  return (
    open && (
      <div
        className={`container-fluid ${position || 'position-fixed'}`}
        style={{ top: '50px', left: 0, zIndex: '500' }}
      >
        <div className={`row alert mb-3 alert-${type}`}>
          <div className='col p-1'>
            <i className='fa fa-exclamation-circle alert-icon mr-1' />
            <strong className='span m-l-sm'>{message}</strong>
            <i className='fa fa-times alert-icon float-right' onClick={props.onClick || onClick} />
          </div>
        </div>
      </div>
    )
  )
}

export default FlashNotification
