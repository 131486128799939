import {
  fromPairs,
  isArray,
  isObject,
  map,
  mapKeys,
  mapValues,
  omitBy,
  orderBy,
  snakeCase,
  toArray,
  toPairs
} from 'lodash-es'

export function getMetaValue(name) {
  const metaTag = document.querySelector(`meta[name='${name}']`)
  return metaTag ? metaTag.getAttribute('content') : null
}

export const sortKeys = (obj, order = 'asc') => fromPairs(orderBy(toPairs(obj), 0, order))

export const omitByDeep = function (obj, predicate) {
  if (!isObject(obj)) {
    return obj
  } else if (isArray(obj)) {
    return toArray(map(obj, (value) => omitByDeep(value, predicate)))
  }
  return mapValues(omitBy(obj, predicate), (value) => omitByDeep(value, predicate))
}

export const mapValuesDeep = (obj, predicate) => {
  if (!isObject(obj)) {
    return predicate(obj)
  } else if (isArray(obj)) {
    return toArray(map(obj, (value) => mapValuesDeep(value, predicate)))
  }
  return mapValues(obj, (value) => mapValuesDeep(value, predicate))
}

export const isCurrentPage = (hrefMatchRegex) => {
  return window.location.pathname.replace(/\/$/, '').match(hrefMatchRegex)
}

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const partitionObject = (obj, filterFn) => {
  return Object.keys(obj).reduce(
    (result, key) => {
      result[filterFn(obj[key], key) ? 0 : 1][key] = obj[key]
      return result
    },
    [{}, {}]
  )
}

export const snakeCaseObject = (object) => mapKeys(object, (_value, key) => snakeCase(key))
