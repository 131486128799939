export default function () {
  const topNavBar = document.querySelector('.navbar.fixed-top')
  const bottomNavbar = document.querySelector('.navbar.fixed-bottom')

  const topNavbarHeight = topNavBar ? topNavBar.clientHeight : 0
  const bottomNavbarHeight = bottomNavbar ? bottomNavbar.clientHeight : 0
  const additionalVerticalSpace = 15

  window.addEventListener(
    'focus',
    (event) => {
      if (!['INPUT', 'BUTTON'].includes(event.target.nodeName)) {
        return
      }

      let bounding = event.target.getBoundingClientRect()

      let diff = (window.innerHeight || document.documentElement.clientHeight) - (bounding.bottom + bottomNavbarHeight)

      if (diff < 0) {
        window.scrollBy(0, -diff + additionalVerticalSpace)
      } else if (bounding.top < topNavbarHeight) {
        window.scrollBy(0, bounding.top - topNavbarHeight - additionalVerticalSpace)
      }
    },
    true
  )
}
