import { each } from 'lodash-es'

const CHEVRON_UP_ICON_CLASS = 'fa-chevron-up'

export default function () {
  function handleToggle(icon, collapsableBlock) {
    event.preventDefault()
    if (icon.classList.contains(CHEVRON_UP_ICON_CLASS)) {
      icon.classList.remove(CHEVRON_UP_ICON_CLASS)
      icon.classList.add('fa-chevron-down')
      collapsableBlock.style.display = 'none'
    } else {
      icon.classList.remove('fa-chevron-down')
      icon.classList.add(CHEVRON_UP_ICON_CLASS)
      collapsableBlock.style.display = 'block'
    }
  }

  let links = document.querySelectorAll('.collapse-link')

  each(links, (link) => {
    let icon = link.getElementsByTagName('i')[0]
    let collapsableBlock = document.getElementById(link.dataset['body'])
    link.addEventListener('click', handleToggle.bind(this, icon, collapsableBlock))
  })
}
