export default {
  delimiters: {
    thousands: ' ',
    decimal: '.'
  },
  abbreviations: {
    thousand: ' tūkst.',
    million: ' milj.',
    billion: ' mljrd.',
    trillion: ' trilj.'
  },
  ordinal: (_number) => '.',
  currency: {
    symbol: '€'
  }
}
