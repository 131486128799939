import * as Sentry from '@sentry/browser'
import { getMetaValue } from 'utils'

const dsn = getMetaValue('sentry_dsn')
const environment = getMetaValue('environment')

const handleBeforeSend = (event, hint) => {
  // NOTE: differentiate axios errors by status codes
  if (hint?.originalException?.isAxiosError && typeof hint.originalException?.response?.status === 'number') {
    event.fingerprint = ['AxiosError', hint.originalException.response.status]
  }

  return event
}

Sentry.init({
  dsn: dsn,
  environment: environment,
  beforeSend: handleBeforeSend
})
