import { registerComponent } from '../setup'

registerComponent('QualificationRequestDecision', () => import('./QualificationRequestDecision'))
registerComponent(
  'Qualifications.QualificationRequests.Step1Form',
  () => import('./qualifications/qualificationRequests/step1Form')
)
registerComponent(
  'Qualifications.QualificationRequests.Step2Form',
  () => import('./qualifications/qualificationRequests/step2Form')
)
registerComponent('SupplierUserTenderAccesses', () => import('./SupplierUserTenderAccesses'))
registerComponent('TenderObjectLetterForm', () => import('./TenderObjectLetterForm/letterForm'))
registerComponent('Attachments', () => import('./attachments'))
registerComponent('AttachmentsFileInput', () => import('./inputs/attachmentsFileInput'))
registerComponent('Chat', () => import('./chat'))
registerComponent('ChatModal', () => import('./chat/chatModal'))
registerComponent('ErrorFallback', () => import('./errorFallback'))
registerComponent('Tenders.Proposals.Estimate', () => import('./tenders/proposals/estimate'))
registerComponent('PricePollProposalStatefulButtons', () => import('./externalPricePollProposals/statefulButtons'))
registerComponent('FileInput', () => import('./fileInput'))
registerComponent('FilesDropdown', () => import('./filesDropdown'))
registerComponent('CollectionSelectInput', () => import('./inputs/collectionSelect'))
registerComponent('CreatableCollectionSelectInput', () => import('./inputs/creatableCollectionSelect'))
registerComponent('DateInput', () => import('./inputs/dateInput'))
registerComponent('DateRangeInput', () => import('./inputs/dateRangeInput'))
registerComponent('DateTimeInput', () => import('./inputs/dateTimeInput'))
registerComponent('WysiwygInput', () => import('./inputs/wysiwygInput'))
registerComponent('SetLetterExaminators', () => import('./letters/examinators/setExaminators'))
registerComponent('LetterExamination', () => import('./letters/examinators/examination'))
registerComponent('LetterRepeatExamination', () => import('./letters/examinators/repeatExamination'))
registerComponent('LetterRecipientsEdit', () => import('./letters/recipientsEdit'))
registerComponent('ModalTooltip', () => import('./modalTooltip'))
registerComponent(
  'PricePolls.Common.BestValueEvaluationCriteria',
  () => import('./pricePolls/common/bestValueEvaluationCriteria')
)
registerComponent('PricePollInvolvedPeople', () => import('./pricePolls/involvedPeople'))
registerComponent('PricePolls.PricePollForm', () => import('./pricePolls/pricePollForm'))
registerComponent('PricePollProlong', () => import('./pricePolls/prolong'))
registerComponent('PricePollRequestReview', () => import('./pricePolls/requestReview'))
registerComponent('PricePolls.ExternalResults', () => import('./pricePolls/externalResults'))
registerComponent('PricePollReview', () => import('./pricePolls/review'))
registerComponent('PricePolls.Common.StopPart', () => import('./pricePolls/common/stopPart'))
registerComponent('PricePollAttractExpert', () => import('./pricePolls/summary/attractExpert'))
registerComponent('PricePollDecisionsTable', () => import('./pricePolls/summary/decisions/decisionsTable'))
registerComponent('PricePolls.InviteSuppliers', () => import('./pricePolls/inviteSuppliers'))
registerComponent('PricePolls.StopDecision', () => import('./pricePolls/summary/decisions/stopDecision'))
registerComponent('PricePollInappropriateModal', () => import('./pricePolls/summary/inappropriateModal'))
registerComponent('PricePollInnerCommunication', () => import('./pricePolls/summary/innerCommunication'))
registerComponent('PricePollPartEvaluation', () => import('./pricePolls/summary/partEvaluation'))
registerComponent('PricePollPriceReviewModal', () => import('./pricePolls/summary/price_review_modal'))
registerComponent('PricePollPricesCorrectionModal', () => import('./pricePolls/summary/prices_correction_modal/modal'))
registerComponent('PricePollProposalRowCommentModal', () => import('./pricePolls/summary/proposalRowCommentModal'))
registerComponent('PricePolls.TerminatePricePollOrPart', () => import('./pricePolls/summary/terminatePricePollOrPart'))
registerComponent(
  'Tenders.TenderObjects.Summary.PriceProportionForm',
  () => import('./tenders/tenderObjects/summary/priceProportionForm')
)
registerComponent('PricePolls.External.ProposalForm', () => import('./pricePolls/external/proposalForm'))
registerComponent('ProlongTenderObjectButton', () => import('./prolongTenderObjectButton'))
registerComponent('QualificationForm', () => import('./qualificationForm'))
registerComponent('Admin.Qualifications.SystemForm', () => import('./admin/qualifications/systemForm'))
registerComponent('Admin.WorkTypeForm', () => import('./admin/workTypeForm'))
registerComponent('QualificationRequestApproval', () => import('./qualificationRequestApproval'))
registerComponent('QualificationRequestEnsurementEntered', () => import('./qualificationRequestEnsurementEntered'))
registerComponent('RequestsExportButton', () => import('./requestsExportButton'))
registerComponent('RequestsWithFormRowsExportButton', () => import('./requestsWithFormRowsExportButton'))
registerComponent('Tenders.ProposalLetterTemplate', () => import('./tenders/proposalLetterTemplate'))
registerComponent('Tenders.Proposals.StatefulButtons', () => import('./tenders/proposals/statefulButtons'))
registerComponent('UserAccesses', () => import('./userAccesses'))
registerComponent('Admin.UserForm', () => import('./admin/userForm'))
registerComponent('External.Supplier', () => import('./external/supplier'))
registerComponent('Admin.Supplier', () => import('./admin/supplier'))
registerComponent('Tenders.Affirmation', () => import('./tenders/affirmation'))
registerComponent('Tenders.TenderShow', () => import('./tenders/tenderShow'))
registerComponent('Tenders.TenderObjects.Edit', () => import('./tenders/tenderObjects/edit'))
registerComponent('Tenders.TenderObjects.Summary.Outcome', () => import('./tenders/tenderObjects/summary/outcome'))
registerComponent('Tenders.TenderForm', () => import('./tenders/tenderForm'))
registerComponent(
  'PricePolls.External.Proposal.CancelRecallButton',
  () => import('./externalPricePollProposals/statefulButtons/cancelRecallButton')
)
registerComponent('FriendlyCaptcha', () => import('./friendlyCaptcha'))
registerComponent('Common.DestroyButton', () => import('./common/destroyButton'))
registerComponent(
  'Tenders.TenderObjects.Summary.ProposalAttributesCorrector',
  () => import('./tenders/tenderObjects/summary/proposalAttributesCorrector')
)
registerComponent(
  'PricePolls.External.Common.PricePollData',
  () => import('./pricePolls/external/common/pricePollData')
)
registerComponent('External.Users.RegistrationForm', () => import('./external/users/registrationForm'))
registerComponent('SessionTimeoutWarning', () => import('./sessionTimeout'))
