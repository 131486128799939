import 'cookieconsent/build/cookieconsent.min.css'
import 'cookieconsent/build/cookieconsent.min.js'
import I18n from 'i18n/index.js'
import { getMetaValue } from 'utils'

const message = getMetaValue('supported_browser_consent_message')

export default function () {
  window.cookieconsent.initialise({
    cookie: {
      name: 'LeisConsent',
      domain: window.location.hostname
    },
    palette: {
      popup: {
        background: '#5e5d5a',
        text: '#fff'
      },
      button: {
        background: '#fff',
        text: '#234a97'
      }
    },
    theme: 'classic',
    showLink: false,
    content: {
      message: message,
      dismiss: I18n.t('consent.confirm'),
      showLink: false
    },
    position: 'top'
  })
}
