import { registerComponent } from 'r/setup'

registerComponent('Procurements.Advertisement', () => import('./advertisement/'))
registerComponent('Procurements.Procurement', () => import('./procurement/'))
registerComponent('Procurements.Regulation', () => import('./regulation/'))
registerComponent('Procurements.RegulationShow', () => import('./regulationShow/'))
registerComponent('Procurements.RegulationTemplate', () => import('./regulationTemplate/'))
registerComponent('Procurements.ScheduleTemplate', () => import('./scheduleTemplate/'))
registerComponent('Procurements.Summary', () => import('./summary/'))
registerComponent('Procurements.SummaryAffirmation', () => import('./affirmation/summaryAffirmation'))
registerComponent(
  'Procurements.SpsilSummaryAffirmationContent',
  () => import('./affirmation/spsilSummaryAffirmationContent')
)
registerComponent('Procurements.RegulationAffirmation', () => import('./affirmation/regulationAffirmation'))

// External components
registerComponent('Procurements.External.Procurement', () => import('./external/procurement'))
registerComponent('Procurements.External.Offer', () => import('./external/offer'))
registerComponent('Procurements.External.Results', () => import('./external/results'))
registerComponent('Procurements.ManagersWorkloadsModalButton', () => import('./managersWorkloadsModalButton'))
