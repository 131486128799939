import browserConsent from './browser_consent'
import cookieConsent from './cookie_consent'
import datagridCheckboxes from './datagrid_checkboxes'
import focusReposition from './focus_reposition'
import internalSignIn from './internal_sign_in'
import navigationHighlight from './navigation_highlight'
import pricePollSummary from './price_poll_summary'
import rolesFormCheckboxes from './rolesFormCheckboxes'
import stickies from './stickies'
import tenderSummary from './tender_summary'
import tooltips from './tooltips'
import transitionQualificationRequestToInProgress from './transition_qualification_request'

function onLoad() {
  stickies()
  tooltips()
  transitionQualificationRequestToInProgress()
  datagridCheckboxes()
  focusReposition()
  navigationHighlight()
  tenderSummary()
  cookieConsent()
  browserConsent()
  pricePollSummary()
  internalSignIn()
  rolesFormCheckboxes()
}

document.addEventListener('turbolinks:load', onLoad)
