import { t } from 'i18n'
import * as yup from 'yup'

yup.addMethod(yup.string, 'email', function validateEmail(message) {
  return this.matches(/([\w+-].?)+@[a-z\d-]+(\.[a-z0-9-]+)*\.[a-z0-9]+/, {
    message,
    name: 'email',
    excludeEmptyString: true
  })
})

yup.addMethod(yup.string, 'personName', function validatePersonName(message) {
  return this.matches(/^[a-zA-ZĀāČčĒēĢģĪīĶķĻļŅņŠšŪūŽž '-]+$/, {
    message,
    name: 'personName',
    excludeEmptyString: true
  })
})

yup.addMethod(
  yup.string,
  'passwordConfirmationMatchesPassword',
  function validatePasswordConfirmationMatchesPassword() {
    return this.oneOf([yup.ref('password'), null], t('activerecord.errors.messages.passwords_must_match'))
  }
)

yup.addMethod(yup.boolean, 'onlyTrueValid', function validateOnlyTrueValid() {
  return this.oneOf([true], t('required'))
})

yup.addMethod(yup.number, 'maxPrecision', function maxPrecision(maxPrecision, message = undefined) {
  const messageScope = message ?? t('errors.messages.max_precision', { count: maxPrecision })

  return this.test('maxPrecision', messageScope, (num) => {
    const decimals = String(num).split('.')[1] || ''
    return decimals.length <= maxPrecision
  })
})

yup.addMethod(yup.number, 'price', function price(maxPrecision = 2, message = undefined) {
  return yup.number().maxPrecision(maxPrecision, message)
})
